/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import $ from 'jquery';
import {Collapse, Modal, Dropdown, Tab, initTE, Tooltip, Chart, Popover} from 'tw-elements';
import * as slick from 'slick-carousel';
import noUiSlider from 'nouislider';
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";
import select2 from 'select2';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Fancybox } from "@fancyapps/ui";
import * as tagManager from "./tagManager";

initTE({Collapse, Modal, Dropdown, Tab, Tooltip, Chart, Popover});

window.$ = $;
window.slick = slick;
window.noUiSlider = noUiSlider;
window.flatpickr = flatpickr;
window.Fancybox = Fancybox;
window.moment = moment;
window.Swal = Swal;
window.tagManager = tagManager;

select2();

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-te-toggle="popover"]'));
tooltipTriggerList.map(function (tooltipTriggerEl) {return new Popover(tooltipTriggerEl);});

/* Update global language of Flatpickr depends of language page */
const pathUrl = window.location.pathname.split('/');
const Language = pathUrl[1];

if(Language == 'es'){
    flatpickr.localize(Spanish);
}

const cookieConsent = localStorage.getItem('cookieConsent');

if(!cookieConsent){
    const cookieText = {
        es: {
            title: "Aviso de Cookies",
            button: "Aceptar todas las cookies",
            text: "Al hacer clic en “Aceptar todas las cookies” aceptas el almacenamiento de cookies en tu dispositivo para mejorar la navegación en el sitio web viajescaribemaya.com, analizar el tráfico y ayudar en nuestras actividades de marketing.",
        },
        en: {
            title: "Cookies Notice",
            button: "Accept all cookies",
            text:
            "By clicking on “Accept all cookies”, you agree to the storing of cookies on your device to enhance the navigation at viajescaribemaya.com, analyze site usage, and assist in our marketing efforts.",
        },
    };

    $("body").append(`
        <div id="cookie-consent" class="cookie-consent">
            <span id="cookie-consent-close" class="cookie-consent__close">
                <span class="material-icons cookie-consent__icon">close</span>
            </span>
            <div class="container">
                <div class="cookie-consent__content">
                    <h6 class="cookie-consent__title">${cookieText[Language === 'es' ? 'es' : 'en']["title"]}</h6>
                    <p class="cookie-consent__text">${cookieText[Language === 'es' ? 'es' : 'en']["text"]}</p>
                    <button class="button cookie-consent__button" id="cookie-consent-accept">${cookieText[Language === 'es' ? 'es' : 'en']["button"]}</button>
                </div>
            </div>
        </div>
    `);
}

$(document).on('click', "#cookie-consent-accept", function(){
    localStorage.setItem("cookieConsent", "true");
    
    removeCookieContainer();
});

$(document).on('click', "#cookie-consent-close", function(){
    removeCookieContainer();
});

function removeCookieContainer(){
    $('#cookie-consent').fadeOut();

    setTimeout(() => {
        $('#cookie-consent').remove();
    }, 1500);
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
