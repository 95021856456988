import './bootstrap';

$(function () {
    /* detecta si el evento es compatible con el dispositivo */
    $.event.special.touchstart = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
        }
    };
    $.event.special.touchmove = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
        }
    };
    $.event.special.wheel = {
        setup: function( _, ns, handle ){
            this.addEventListener("wheel", handle, { passive: true });
        }
    };
    $.event.special.mousewheel = {
        setup: function( _, ns, handle ){
            this.addEventListener("mousewheel", handle, { passive: true });
        }
    };
    

    $('.pre-loader').css('opacity', 0).css('visibility', 'hidden');

    $('#hamburger').on('click', function(e){
        e.preventDefault();

        $(this).toggleClass('hamburger--toggle');
        $('.header-mobile__nav-bg').toggleClass('header-mobile__nav-bg--toggle');
        $('.header-mobile__nav').toggleClass('header-mobile__nav--toggle');
    });
    
    const header = $('#header');
    const headerMobile = $('#header-mobile');

    $(window).on('scroll', function(e){
        e.preventDefault();
        
        let currentScroll = $(window).scrollTop();

        if(currentScroll > 40){
            $('#logo-mobile').attr('src', import.meta.env.VITE_ASSETS_CDN_URL+"web/images/logo-color.png");
            headerMobile.addClass('header-mobile__fixed');
            $('.hamburger__span').addClass('hamburger__span--color');

        }else{
            $('#logo-mobile').attr('src', import.meta.env.VITE_ASSETS_CDN_URL+"web/images/logo-5.png");
            headerMobile.removeClass('header-mobile__fixed');
            $('.hamburger__span').removeClass('hamburger__span--color');
        }
    })

    const pathUrl = window.location.href.split('?');
    $('.header-navbar__menu-item').each(function(){
        const li = $(this.children).attr('href');
        if(pathUrl[0] == li){
            $(this).addClass('header-navbar__menu-item--active');
        }
    })

    $('.header-mobile__a').each(function(){
        const li = $(this).attr('href');
        if(pathUrl[0] == li){
            $(this).addClass('header-mobile__a--active')
        }
    });

    // Obténer la hora actual en Cancún
    const fechaCancun = new Date().toLocaleString("en-US", {timeZone: "America/Cancun"});
    const horaCancun = new Date(fechaCancun).getHours();

    if (horaCancun >= 8 && horaCancun < 16) {
        $('#whatsappIcon').addClass('whatsapp--show');
    }

    $('#landing-header-hamburger').on('click', function(){
        $(this).toggleClass('landing-mob-header__hamburger--toggle');
        $('#landing-header-offcanva').toggleClass('landing-mob-header__offcanva--open');
        $('body').toggleClass('overflow-hidden');
    });
})

$('.gtm-contact-email').on('click', function () {
    tagManager.gtmContact('correo');
});

$('.gtm-contact-movil').on('click', function () {
    tagManager.gtmContact('movil');
});

$('.gtm-whatsApp').on('click', function(){
    tagManager.gtmWhatsApp();
});

/* tag manager de las redes sociales del menu */
$('.gtm-social-facebook').on('click', function () {
    tagManager.gtmShareSocial('facebook', 'menu');
});

$('.gtm-social-instagram').on('click', function () {
    tagManager.gtmShareSocial('instagram', 'menu');
});

$('.gtm-social-twitter').on('click', function () {
    tagManager.gtmShareSocial('twitter', 'menu');
});

/* tag manager de las redes sociales del footer */
$('.gtm-social-facebook-footer').on('click', function () {
    tagManager.gtmShareSocial('facebook', 'footer');
});

$('.gtm-social-instagram-footer').on('click', function () {
    tagManager.gtmShareSocial('instagram', 'footer');
});

$('.gtm-social-twitter-footer').on('click', function () {
    tagManager.gtmShareSocial('twitter', 'footer');
});

$('.gtm-faqs').on('click', function(){
    let question = $(this).attr('data-question');
    tagManager.gtmFrequentQuestions(question);
});

document.addEventListener('livewire:load', function () {

    Livewire.on('successAlert', (status, email, language) => {
        if(status === 'saved'){
            Swal.fire({
                icon: 'success',
                title: language == 'en' ? 'Active subscription' : 'Suscripción activada',
                text: language == 'en' ? 'Your email '+email+' has been successfully registered.' : 'Tu correo '+email+' ha sido registrado correctamente.',
                confirmButtonColor: '#0075DB'
            });

            window.dataLayer.push({
                event: "service",
                action: "newsletter",
            });
        }
    
        if(status === 'duplicate'){
            Swal.fire({
                icon: 'warning',
                title: language == 'en' ? 'Subscription not completed' : 'Suscripción no completada',
                text: language == 'en' ? 'This email is already registered. enter a different one' : 'Este correo ya se encuentra registrado. Ingresa uno diferente.',
                confirmButtonColor: '#0075DB'
            });
        }
    
        if(status === 'error'){

            Swal.fire({
                icon: 'error',
                title: language == 'en' ? 'Error' : 'Error',
                text: language == 'en' ? 'An error has occurred. Try again later' : 'Ha ocurrido un error. Inténtalo más tarde.',
                confirmButtonColor: '#0075DB'
            });
        }
    });

    Livewire.on('updateSeoSettings', (locales, currentLocale, page) => {
        let title = document.title.split(' - ');
        let metaDescription = document.querySelector('meta[name="description"]');
        let description = metaDescription.content.split(' - ');
        if (currentLocale === 'es') {
            document.title = page === 1 ? title[0] : title[0] + ' - Página ' + page;
            metaDescription.setAttribute('content', page === 1 ? description[0] : description[0] + ' - Página ' + page);
        }
        if (currentLocale === 'en') {
            document.title = page === 1 ? title[0] : title[0] + ' - Page ' + page;
            metaDescription.setAttribute('content', page === 1 ? description[0] : description[0] + ' - Page ' + page);
        }

        Object.entries(locales).forEach((locale) => {
            const [key, value] = locale;
            let linkAlternate = document.querySelector(`link[hreflang=${key}]`);
            let linkXDefault = document.querySelector('link[hreflang=x-default]');
            let linkCanonical = document.querySelector('link[rel=canonical]');
            let xDefault = linkXDefault.href.split('?');
            let alternate = linkAlternate.href.split('?');
            let canonical = linkCanonical.href.split('?');
            linkAlternate.setAttribute('href', page === 1 ? alternate[0] : alternate[0] + '?page=' + page);
            linkXDefault.setAttribute('href', page === 1 ? xDefault[0] : xDefault[0] + '?page=' + page);
            linkCanonical.setAttribute('href', page === 1 ? canonical[0] : canonical[0] + '?page=' + page);
        })
    });
});

$("#toggle-password-eye").on('click', function(e){
    e.preventDefault();
    const password = $("#password");
    const passwordIcon = $("#password-eye-icon");
    if(passwordIcon.text() === 'visibility'){
        passwordIcon.text('visibility_off');
        password.prop('type', 'text');
    } else if(passwordIcon.text() === 'visibility_off'){
        passwordIcon.text('visibility');
        password.prop('type', 'password');
    }

});