const getCurrency = (lang) => lang == 'es' ? 'MXN' : 'USD';

export const gtmContact = (type) => {
    window.dataLayer.push({
        event: "service",
        action: "contact",
        type: type,
    });
};

export const gtmShareSocial = (socialMedia, ubication) => {
    window.dataLayer.push({
        event: "service",
        action: "share social",
        social_media: socialMedia,
        ubication: ubication
    });
}

export const gtmGroups = () => {
    window.dataLayer.push({
        event: "service",
        action: "groups"
    });
};

export const gtmNewsLetter = () => {
    window.dataLayer.push({
        event: "service",
        action: "newsletter",
    });
};

export const gtmWhatsApp = () => {
    window.dataLayer.push({
        event: "service",
        action: "whatsapp"
    });
};

export const gtmBanners = (title) => {
    window.dataLayer.push({
        event: "service",
        action: "banners",
        title: title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase()
    });
};

export const gtmShowBooking = (title) => {
    window.dataLayer.push({
        event: "service",
        action: "booking",
        title: title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase()
    })
}

export const gtmSearchMotor = (location, month, type) => {
    window.dataLayer.push({
        event: 'service',
        event: 'search',
        ubication: location.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').replace(/-/g, '').toLocaleLowerCase(),
        title: month.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase(),
        type: type.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase()
    })
}

export const gtmMoreInformation = (title, service) => {
    window.dataLayer.push({
        event: 'service',
        action: 'more information',
        title: title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase(),
        service: service.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase()
    })
}

export const gtmFunnel = (title, service, coupon) => {
    window.dataLayer.push({
        event: 'service',
        action: 'funnel',
        title: title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').replace(/-/g, '').toLocaleLowerCase(),
        service: service.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase(),
        coupon: coupon.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLocaleLowerCase()
    })
}

export const gtmPromotions = (service) => {
    window.dataLayer.push({
        event: 'service',
        action: 'promociones',
        service: service.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').replace(/-/g, '').toLocaleLowerCase(),
    })
}

// Listo
export const gtmFrequentQuestions = (question) => {
    let questionWithoutTag = question.replace(/(<([^>]+)>)/ig, '');
    let questionTitle = questionWithoutTag.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, '').toLowerCase();

    window.dataLayer.push({
        event: "service",
        action: "frequent questions",
        title: questionTitle,
    });
};

/* Eventos Ecomerce */
// Listo
export const gtmViewItemList = (activityList, lang, activityType) => {
    let currency = getCurrency(lang);
    let items = "";

    if (activityType == 'tours') {
        items = activityList.map(tour => {
            let itemName = tour.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

            return {
                item_id: tour.id,
                item_name: itemName,
                coupon: "",
                currency: currency,
                discount: "",
                item_brand: "actividades",
                price: tour.price_with_discount,
                quantity: 1,
            };
        });
    }

    if (activityType == 'transportacion') {
        items = activityList.map(transfer => {
            let itemName = transfer.transfer_title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/([^a-z0-9 ._-]+)/gi, "").toLowerCase();

            return {
                item_id: transfer.transfer_id,
                item_name: itemName,
                coupon: "",
                currency: currency,
                discount: "",
                item_brand: "transportacion",
                price: transfer.transfer_price,
                quantity: 1,
            };
        });
    }

    /* Cuando el usuario visualice la lista de productos */
    window.dataLayer.push({
        event: "view_item_list",
        item_list_name: activityType,
        ecommerce: {
            items: items,
        }
    });
};


// Listo
export const gtmViewItem = (activity, lang) => {
    let tourName = lang == 'en' ? activity.title_web.en : activity.title_web.es;
    tourName = tourName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const currency = getCurrency(lang);
    const price = lang == 'en' ? activity.first_service.price_adult : activity.first_service.price_adult_mxn;
    const city = activity.cities.slug.replace('-', ' ');

    /* Cuando el usuario visualiza el producto, no aplica para traslados */
    window.dataLayer.push({
        event: "view_item",
        ecommerce: {
            currency: currency,
            value: price,
            items: [{
                item_id: activity.id,
                item_name: tourName,
                coupon: "",
                currency: currency,
                discount: "",
                item_brand: "actividades",
                item_category: city,
                price: price,
                quantity: 1
            }],
        },
    });
};

// Listo
export const gtmSelectItem = (service, lang) => {
    const currency = getCurrency(lang);
    const itemListName = service.product_type == "actividades" ? "tours" : "transportacion";
    const tourName = service.tourName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();

    if (service.product_type == "actividades") {
        const city = service.city.replace('-', " ");
        var dataItem = [{
            item_id: service.id,
            item_name: tourName,
            coupon: "",
            currency: currency,
            discount: "",
            item_brand: "actividades",
            item_category: city,
            price: service.totalTour,
            quantity: 1
        }];
    }

    if (service.product_type == "transporte") {
        const typeOfVehicle = service.tourName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
        let transferType = "";

        if (service.info.transfer_type == 'Redondo- Aeropuerto - Hotel - Aeropuerto') {
            transferType = 'aeropuerto - hotel - aeropuerto';
        } else if (service.info.transfer_type == 'Desde el Aeropuerto') {
            transferType = 'aeropuerto - hotel';
        } else if (service.info.transfer_type == 'Hacia al Aeropuerto') {
            transferType = 'hotel - aeropuerto';
        }

        var dataItem = [{
            item_id: service.id,
            item_name: tourName,
            coupon: "",
            currency: currency,
            discount: "",
            item_brand: "actividades",
            item_category: "transportacion",
            item_category2: transferType,
            item_category3: typeOfVehicle,
            price: service.totalTour,
            quantity: 1
        }];
    }

    /* Cuando el usuario le de clic al boton de "reservar" */
    window.dataLayer.push({
        event: "select_item",
        item_list_name: itemListName,
        items: dataItem,
    });
};

// Listo
export const gtmPurchase = (purchaseDetails, lang) => {
    const currency = getCurrency(lang);
    const tourName = purchaseDetails.activity_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    let items = "";

    if (purchaseDetails.type_product == "activities") {
        items = [{
            item_id: purchaseDetails.id_details,
            item_name: tourName,
            coupon: "",
            currency: currency,
            discount: "",
            item_brand: "actividades",
            item_category: "cancun",
            price: purchaseDetails.total_price,
            quantity: 1,
        }];
    }

    if (purchaseDetails.type_product == "transfers") {
        const transferType = purchaseDetails.type_transfer.toLowerCase();

        items = [{
            item_id: purchaseDetails.id_transfer,
            item_name: tourName,
            item_brand: "transportacion",
            item_category: tourName,
            item_category2: transferType,
            currency: currency,
            coupon: "",
            price: purchaseDetails.total_price,
            quantity: 1,
        }];
    }

    /* Cuando el usuario llega a la vista de pago completado */
    window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: purchaseDetails.id,
            value: purchaseDetails.total_price,
            currency: currency,
            items: items,
        },
    });
};

// Listo
export const gtmAddToCart = (service, lang) => {
    const currency = getCurrency(lang);
    const tourName = service.tourName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    let item = "";

    if (service.product_type == "actividades") {
        const city = service.city.replace('-', " ");

        item = [{
            item_id: service.id_servicio,
            item_name: tourName,
            currency: currency,
            item_brand: "actividades",
            item_category: city,
            price: service.totalTour,
            quantity: 1,
        }];
    }

    if (service.product_type == "transporte") {
        const typeOfVehicle = service.tourName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z]/g, "").toLowerCase();
        let transferType = "";

        if (service.info.transfer_type == 'Redondo- Aeropuerto - Hotel - Aeropuerto') {
            transferType = 'aeropuerto - hotel - aeropuerto';
        } else if (service.info.transfer_type == 'Desde el Aeropuerto') {
            transferType = 'aeropuerto - hotel';
        } else if (service.info.transfer_type == 'Hacia al Aeropuerto') {
            transferType = 'hotel - aeropuerto';
        }

        item = [{
            item_id: service.id,
            item_name: tourName,
            currency: currency,
            item_brand: "activididades",
            item_category: "transportacion",
            item_category2: transferType,
            item_category3: typeOfVehicle,
            price: service.totalTour,
            quantity: 1,
        }];
    }

    /* Cuando el usuario da click en reservar para ir al checkout-cart */
    window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            currency: currency,
            value: service.totalTour,
            items: item
        },
    });
};

export const gtmAddPaymentInfo = (cartData, paymentMethod, lang) => {
    const currency = getCurrency(lang);
    const tourName = cartData.tourName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    let items = "";

    if (cartData.product_type == 'actividades') {
        const city = cartData.city.replace('-', " ");;
        items = [{
            item_id: cartData.id,
            item_name: tourName,
            coupon: cartData.coupon,
            currency: currency,
            discount: "",
            item_brand: cartData.product_type,
            item_category: city,
            price: cartData.totalTour,
            quantity: 1,
        }];
    }

    if (cartData.product_type == 'transporte') {
        const typeOfVehicle = cartData.tourName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z]/g, "").toLowerCase();
        let transferType = "";

        if (cartData.info.transfer_type == 'Redondo- Aeropuerto - Hotel - Aeropuerto') {
            transferType = 'aeropuerto - hotel - aeropuerto';
        } else if (cartData.info.transfer_type == 'Desde el Aeropuerto') {
            transferType = 'aeropuerto - hotel';
        } else if (cartData.info.transfer_type == 'Hacia al Aeropuerto') {
            transferType = 'hotel - aeropuerto';
        }

        items = [{
            item_id: cartData.id,
            item_name: tourName,
            coupon: cartData.coupon,
            currency: currency,
            discount: "",
            item_brand: "actividades",
            item_category: "transportacion",
            item_category2: transferType,
            item_category3: typeOfVehicle,
            price: cartData.totalTour,
            quantity: 1,
        }];
    }

    /* Cuando el usuario da click a pagar */
    window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            currency: currency,
            value: cartData.totalTour,
            coupon: cartData.coupon,
            payment_type: paymentMethod,
            items: items,
        }
    });
};